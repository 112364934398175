import React from "react"
import { Link } from "gatsby"
import Firma from "../images/inside.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../components/layout"
import Onas from "./about"
import SEO from "../components/seo"
// import Image from "../components/image"

const IndexPage = () => (
  <Layout>
    <SEO />
    <div>
      <header className="content">
        <div className="main__header">
          <img src={Firma} alt="wewnatrz firmy" className="only-mobile img" />
          <ul className="main__header--lista">
            <Link to="/uslugi/stomatologia-estetyczna">
              <li className="main__header--li">
                {/* <i className="fas fa-2x fa-tooth" /> */}
                <FontAwesomeIcon
                  icon="tooth"
                  size="2x"
                />
                <br />
                Stomatologia estetyczna
              </li>
            </Link>
            <Link to="/uslugi/implantologia">
              <li className="main__header--li">
                {/* <i className="fas fa-2x fa-tooth" /> */}
                <FontAwesomeIcon
                  icon="tooth"
                  size="2x"
                />
                <br />
                Implantologia
              </li>
            </Link>
            <Link to="/uslugi/kompleksowa-opieka">
              <li className="main__header--li">
                {/* <i className="fas fa-2x fa-tooth" /> */}
                <FontAwesomeIcon
                  icon="tooth"
                  size="2x"
                />
                <br />
                Kompleksowa opieka
                <br />
                stomatologiczna
              </li>
            </Link>
            <Link to="/uslugi/medycyna-estetyczna">
              <li className="main__header--li">
                {/* <i className="fas fa-2x fa-tooth" /> */}
                <FontAwesomeIcon
                  icon="tooth"
                  size="2x"
                />
                <br />
                Medycyna estetyczna
              </li>
            </Link>
          </ul>
        </div>
      </header>
      <Onas />
    </div>
  </Layout>
)

export default IndexPage
